.entry-index {
    margin-top: 0;
    padding-top: 1em;
}

.entry-button{
    display: inline-block;
    padding: 12px 25px;
    margin: 10px auto;
    font-size: 1.25em;
    text-align: left;
    text-decoration: none;
    outline: none;
    color: #fff;
    background-color: #8aa4ec;
    border: none;
    border-radius: 15px;
    box-shadow: 0 5px #b5b4b4;
    width: 90%;
    max-width: 400px;
}

.entry-button:hover {background-color: #878dd5}

.entry-button:active {
  background-color: #8aa4ec;
  box-shadow: 0 5px #b5b4b4;
  transform: translateY(4px);
}

#dimScreen {
    position: fixed;
    padding:0;
    margin:0;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    background:rgba(45, 45, 45, 0.7);
}

.dictionaryCard {
    display: flex;
    flex-direction: column;
    background-color: rgb(253, 253, 253);
    margin: 2em auto;
    border: 2px none rgb(121, 121, 121);
    border-radius: 5%;
    padding: 10px 15px 15px 15px;
    box-shadow:  12px 12px 6px -6px rgba(114, 113, 113, 0.2);
    width: 85vw;
    max-height: 80vh;
    max-width: 600px;
}

.playButton{
    width: 2em;
}

.flexbox {
    display: flex;
}

.justyfy-center {
    justify-content: center;
}

.flex-end {
    justify-content: flex-end;
}

.grid {
    display: grid;
    grid-template-columns: 1fr 5fr;
    gap: 10px;
    margin: 5px 10px;
}

.meme {
    max-width: 90%;
    max-height: 30vh;
    object-fit: contain;
    align-self: center;
}

.font-red {
    color: red;
}

.cardText {
    margin: 0;
}

.dark-blue{
    color: darkblue;
}

.card-float{
    position: absolute;
    margin: 10vh auto; 
    top: 15px;
    left: 0;
    right: 0;
    z-index: 2;
}

.scroll{
    overflow-y:auto;
}

@media only screen and (min-width: 768px){

    .entrys {
        margin: 0 13vw;
        text-align: center;
    }

    .entry-button{
        width: 25vw;
    }

    .entries-container{
        display: flex;
        margin: 10vm 0;
        gap: 0.5em;
    }

}
