h1 .menuItemTitle {
    margin: 0;
    padding-top: 1em;
    font-size: 1.5em;
    color: rgb(2, 83, 145);
}

.conversationCard {
    background-color: rgb(253, 253, 253);
    margin: 2em auto;
    border: 2px none rgb(121, 121, 121);
    border-radius: 5%;
    padding: 15px;
    box-shadow:  12px 12px 6px -6px rgba(114, 113, 113, 0.2);
    max-width: 300px;
}


.conversationCardImage {
    width: 70%;
    box-shadow: 12px 12px 6px -6px rgba(114, 113, 113, 0.2);
    border: solid gray 5px;
    border-radius:5%;
}

.conversation-piece {
    margin: 0 1em;
    padding-top: 5em;
    padding-bottom: 2em;
}

.profilePic {
    width: 3em;
}

.audioButton{
    display: flex;
    align-items: center;
    background-color: white;
    border-radius: 100%;
    border-bottom: solid 2px rgb(181, 182, 182);
    cursor: pointer;
}

.audioButton:active {
    border-bottom: none;
}

.lineBlock {
    display: flex;
    align-items: center;
}

.icon-small {
    width: 1.5em;
    padding: 0.4em;
}

.dialogBubble {
    border: solid lightgray 3px;
    border-radius: 20px;
    margin: 10px;
    padding: 0 10px;
    z-index: 0;
    position: relative;
}

.dialogBubble:before,
.dialogBubble:after {
	content: ' ';
	position: absolute;
	width: 0;
	height: 0;
}

.dialogBubble:before {
	left: -25px;
	top: calc(50% - 2px);
	border: 13px solid;
	border-color:transparent lightgray transparent transparent;
}

.dialogBubble:after {
	left: -20px;
	top: 50%;
	border: 11px solid;
	border-color:transparent #f5fafd transparent  transparent;
}

.inline-flex{
    display: inline-flex;
    align-items: center;
}

.dialog-fr{
    margin-left: 10px;
    align-items: center;
    text-align: left;
    color: darkblue;
    font-size: 1.25em;
}

.dialog-nonfr{
    text-align: left;
    margin-left: 46px;
    margin-top: 0;
    line-height: 130%;
}

.dimScreen {
    position:fixed;
    padding:0;
    margin:0;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    background:rgba(45, 45, 45, 0.7);
    z-index: 1;
}

.withIndex {
    border-bottom: dotted black 2px;
    cursor: pointer;
}

.fixed-top {
    position: fixed;
    top: 4em;
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.717);
    z-index: 1;
}

@media only screen and (min-width: 768px) {
    .lineBlock {
        margin-left: 2em;
    }
}