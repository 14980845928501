.HPbody {
    padding-bottom: 4em;
}

.beaverImg {
    object-fit: contain;
    height: 370px;
    margin-top: 2em;
    margin-bottom: 2em;
}

.pitchLine{
    margin: 1.5em
}

.pitchLine-single h1{
    font-size: 1.5em;
    color: rgb(121, 121, 121);
    font-style: italic;
    margin-top: 0;
}

.pitchLine-single {
    background-color: rgb(253, 253, 253);
    margin-bottom: 2em;
    border: 2px none rgb(121, 121, 121);
    border-radius: 5%;
    padding: 15px;
    box-shadow:  8px 8px 6px -6px rgba(114, 113, 113, 0.2);
}

.pitchLine-image {
    height: 10em;
    width: 10em;
}

.HPButton {
    text-decoration: none;
    color: white;
    background-color: rgb(145, 202, 252);
    padding: 0.7em;
    border-radius: 5px;
    border-bottom: solid 3px rgb(105, 151, 252);
}

.HPButton:active {
    border-bottom:none;
}

.HPfooter {
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2em;
    background-color: rgb(121, 189, 248);
}

.poutine {
    object-fit: contain;
    max-height: 1.5em;
}

.contactUs {
    margin-bottom: 0;
}

form {
    text-align: left;
}

form textarea {
    border: solid 0 black;
    border-radius: 4px;
}

.sendButton {
    font-family: 'ABeeZee';
    font-size: 1em;
    text-decoration: none;
    color: black;
    background-color: rgb(189, 222, 251);
    border-radius: 5px;
    border: solid 0 black;
    border-bottom: solid 2px rgb(63, 124, 255);
    padding: 0.3em;
    margin-top: 0.5em;
}

.sendButton:active {
    border-bottom: none;
}

@media only screen and (min-width: 768px){
    .pitchLine{
        display: flex;
        justify-content: center;
        gap: 1em;
    }

    .pitchLine-single{
        width: 25vw;
        max-width: 300px;
    }

    .HPfooter {
        flex-direction: row;
        gap: 3em;
    }
}