.menuItemTitle {
    margin: 0;
    padding-top: 40px;
    font-size: 1.5em;
    color: rgb(2, 83, 145);
}

.categoryCard {
    background-color: rgb(253, 253, 253);
    margin: 2em auto;
    border: 2px none rgb(121, 121, 121);
    border-radius: 5px;
    padding: 15px;
    box-shadow:  12px 12px 6px -6px rgba(114, 113, 113, 0.2);
    max-width: 300px;
}

.categoryLink{
    text-decoration: none;
    color: black;
    max-width: 300px;
    margin: 2em auto;
    transition: transform ease 0.5s;
}

.categoryLink :active{
    box-shadow: none;
}

.categoryLink :hover{
    transform: translate(0, -10px);
}

.categoryCard *:hover{
    transform: none;
}

.categoryCardTitle {
    font-size: 1.25em;
    margin-bottom: 0.5em;
}

.categoryCardContent{
    display: flex;
}

.categoryCardImage {
    height: 8em;
}

.categoryCardQuote{
    font-size: 0.9em;
    text-align: left;
}

@media only screen and (min-width: 768px){
    .categoryCardContainer{
        display: flex;
        justify-content: center;
        gap: 1em;
        flex-wrap: wrap;
    }

    .categoryCard{
        width: 25vw;
        max-width: 300px;
        min-width: 250px;
    }
}